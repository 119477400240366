@font-face {
  font-family: font;
  src: url("./assets/fonts/normal.woff2");
  font-weight: normal;
}

@font-face {
  font-family: font;
  src: url("./assets/fonts/normal.woff2");
  font-weight: 500;
}

@font-face {
  font-family: font;
  src: url("./assets/fonts/bold.woff2");
  font-weight: 700;
}

@font-face {
  font-family: font-title;
  src: url("./assets/fonts/GentyDemo-Regular.ttf");
  font-weight: normal;
}

a {
  cursor: pointer;
}

body {
  background-color: #057e03;
  color: #fff;
  font-family: font, sans-serif;
  font-weight: normal;
}

.App {
  overflow: hidden;
}

.font-title {
  font-family: font-title, sans-serif;
}

.text-gradient {
  background: linear-gradient(
      90deg,
      #fd5a05 40.01%,
      #fa5505 42.01%,
      #ec3d03 55.01%,
      #e22c01 68%,
      #dc2100 83%,
      #da1e00 100%
    ),
    #ffffff;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.bg-gradient {
  background-color: #1a9df0;
}

.bg-tokenomics {
  background-position: 50% 0;
  background-size: 100% 100%;
}

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  width: 36px;
  height: 30px;
  right: 36px;
  top: 36px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: #373a47;
}

/* Color/shape of burger icon bars on hover*/
.bm-burger-bars-hover {
  background: #a90000;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  height: 24px;
  width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
  background: #bdc3c7;
}

/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #373a47;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  color: #b8b7ad;
  padding: 0.8em;
}

/* Individual item */
.bm-item {
  display: inline-block;
}

/* Styling of overlay */
.bm-overlay {
  background: rgba(0, 0, 0, 0.3);
}
